import * as React from "react"
import { graphql } from "gatsby"
import { Container } from "../components/Container"
import { SectionHeader } from "../components/SectionHeader"
import { Layout } from "../layouts/default"

export default function Page404({ data }) {
  return (
    <Layout siteMetadata={data.allSite.nodes[0].siteMetadata}>
      <Container as="section">
        <SectionHeader
          title="Oops, page not found"
          subtitle="we can't seem to find the page you're looking for"
        />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query NotFoundPage {
    ...MetaDataQuery
  }
`
